import {
  UnstyledButton,
  UnstyledButtonProps,
  Group,
  Avatar,
  Text,
  createStyles,
  getStylesRef,
} from "@mantine/core";

import { forwardRef } from 'react';

import { IconChevronRight } from "@tabler/icons-react";

const useStyles = createStyles((theme) => ({
  user: {
    display: 'block',
    width: '100%',
    padding: theme.spacing.md,
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[2],
    },
  },
}));

const UserButton = forwardRef(function UserButton({ name, email, icon, ...others }, ref) {
  const { classes } = useStyles();

  return (
    <UnstyledButton {...others} ref={ref} className={classes.user}>
      <Group style={{ flex: 1, flexWrap: "nowrap" }}>
        <Avatar radius="xl" color="primary">{name?.substring(0, 1)?.toString()}</Avatar>

        <div style={{ flex: 1, flexWrap: "nowrap" }}>
          <Text size="sm" weight={500}>
            {name}
          </Text>

          <Text color="dimmed" size="xs" style={{
            "maxWidth": "150px",
            "whiteSpace": "nowrap",
            "overflow": "hidden",
            "textOverflow": "ellipsis"
          }} >
            {email}
          </Text>
        </div>

        {icon || <IconChevronRight size="0.9rem" stroke={1.5} />}
      </Group>
    </UnstyledButton>
  );
})

export { UserButton }