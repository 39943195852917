import { useState, useEffect } from "react";
import {
  createStyles,
  Navbar,
  UnstyledButton,
  Badge,
  Text,
  Loader,
  Group,
  rem,
  Tooltip,
  Flex,
  Center,
  ActionIcon,
  Menu,
  getStylesRef,
  MultiSelect,
} from "@mantine/core";

import dayjs from "dayjs";

import {
  IconUser,
  IconUsersGroup,
  IconCalendarEvent,
  IconBrandProducthunt,
  IconBuilding,
  IconSelector,
  IconMoodDollar,
  IconBook2,
  IconGavel,
  IconSpeakerphone,
  IconTimelineEvent,
  IconPoint,
  IconListDetails,
  IconLayoutDashboard, IconFileInfo,
  IconLogout,
  IconCalendarPlus,
  IconHeart,
  IconStar,
  IconMessage,
  IconPlayerPause,
  IconTrash,
  IconSwitchHorizontal,
  IconUserQuestion,
} from "@tabler/icons-react";

import { MonthPickerInput } from "@mantine/dates";

import { ColorToggle } from "./ColorToggle";

import { openChangePassword } from "./ChangePassword";

import { UserButton } from "./UserButton";

import { useTabs } from "./lib/Tabs";

import useSWR, { useSWRConfig } from "swr";
import { forwardRef } from 'react';
import { useUser, useSupabaseClient } from "@supabase/auth-helpers-react";

import { ScrollArea } from '@mantine/core';
import { act } from "react-dom/test-utils";


const Item = forwardRef(({ label, value, ...others }, ref) => {
  return (
    <div ref={ref} {...others} style={{ ...others.style, marginTop: "4px", marginBottom: "4px" }}>
      <Flex align="center">
        <div>{label}</div>
      </Flex>
    </div>
  );
});

function Value({
  value,
  label,
  onRemove,
  classNames,
  ...others
}) {
  return (
    <div {...others}>
      <Badge size="sm">
        {label}
      </Badge>
    </div>
  );
}


const useStyles = createStyles((theme) => ({
  navbar: {
    paddingTop: 0,
    borderRight: `${rem(1)} solid ${theme.colorScheme === "dark"
      ? theme.colors.dark[4]
      : theme.colors.gray[3]
      }`
  },

  section: {
    marginLeft: `calc(${theme.spacing.md} * -1)`,
    marginRight: `calc(${theme.spacing.md} * -1)`,
    marginBottom: theme.spacing.md,
    "&:not(:last-of-type)": {
      borderBottom: `${rem(1)} solid ${theme.colorScheme === "dark"
        ? theme.colors.dark[4]
        : theme.colors.gray[3]
        }`,
    },
  },

  searchCode: {
    fontWeight: 700,
    fontSize: rem(10),
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[7]
        : theme.colors.gray[0],
    border: `${rem(1)} solid ${theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[2]
      }`,
  },

  mainLinks: {
    paddingLeft: `calc(${theme.spacing.md} - ${theme.spacing.xs})`,
    paddingRight: `calc(${theme.spacing.md} - ${theme.spacing.xs})`,
    paddingBottom: theme.spacing.md,
  },

  mainLink: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    fontSize: theme.fontSizes.xs,
    padding: `${rem(8)} ${theme.spacing.xs}`,
    borderRadius: theme.radius.sm,
    fontWeight: 500,
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
      color: theme.colorScheme === "dark" ? theme.white : theme.black,
    },
  },

  mainLinkInner: {
    display: "flex",
    alignItems: "center",
    flex: 1,
  },

  mainLinkIcon: {
    ref: getStylesRef("icon"),
    marginRight: theme.spacing.sm,
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[2]
        : theme.colors.gray[6],
  },

  mainLinkBadge: {
    padding: 0,
    width: rem(20),
    height: rem(20),
    pointerEvents: "none",
  },

  collections: {
    paddingLeft: `calc(${theme.spacing.md} - ${rem(6)})`,
    paddingRight: `calc(${theme.spacing.md} - ${rem(6)})`,
    paddingBottom: theme.spacing.md,
  },

  collectionsHeader: {
    paddingLeft: `calc(${theme.spacing.md} + ${rem(2)})`,
    paddingRight: theme.spacing.md,
    marginBottom: rem(5),
  },

  mainLinkActive: {
    "&, &:hover": {
      backgroundColor: theme.fn.variant({
        variant: "light",
        color: theme.primaryColor,
      }).background,
      color: theme.fn.variant({ variant: "light", color: theme.primaryColor })
        .color,
      [`& .${getStylesRef("icon")}`]: {
        color: theme.fn.variant({ variant: "light", color: theme.primaryColor })
          .color,
      },
    },
  },
}));

const links = [
  { icon: IconLayoutDashboard, label: "Painel de Controle", id: "Home", component: "Home" },

  {
    icon: IconBrandProducthunt,
    label: "Projetos",
    id: "project",
    component: "Projects",
  },

  {
    icon: IconUsersGroup,
    label: "Grupos",
    id: "groups",
    component: "Groups",
  },

  {
    icon: IconUser,
    label: "Usuários",
    id: "users",
    component: "Users",
  },

];

export function NavbarSection() {
  const { classes, cx, theme } = useStyles();
  const { activeTab, addTab } = useTabs();

  const user = useUser();
  const supabase = useSupabaseClient();

  const [activeProjects, setActiveProjects] = useState(null);

  const [activeDate, setActiveDate] = useState([dayjs(new Date()).add(-3, "month").toDate(), dayjs(new Date()).add(6, "month").toDate()]);

  const [activeDateDirty, setActiveDateDirty] = useState(true);

  async function updateDateRange(value) {
    const { error } = await supabase
      .from("users")
      .update({ active_date_range: [dayjs(value[0]).startOf("month").format("YYYY-MM-DD"), dayjs(value[1]).endOf("month").format("YYYY-MM-DD")] })
      .eq("id", user.id);

    await supabase.auth.refreshSession();

    try {
      mutateGlobal(
        () => true,
        undefined,
        { revalidate: true }
      )
    }
    catch (ex) {
      console.log(ex);
    }
  }

  useEffect(() => {
    if (activeDate && activeDate[0] && activeDate[1]) {
      updateDateRange(activeDate);
    }
  }, [activeDate]);

  const { mutate: mutateGlobal } = useSWRConfig();

  const { data } = useSWR({
    tableName: "v_row_count",
  });

  const { data: projects, mutate } = useSWR({
    tableName: "project",
    columns: "id, name",
  });

  const onLogoutClick = async () => {
    const { error } = await supabase.auth.signOut();
  };

  const onChangePasswordClick = () => {
    openChangePassword(true);
  };

  useEffect(() => {
    if (user?.user_metadata?.active_projects) {
      setActiveProjects(user?.user_metadata?.active_projects);
    }

    if (user?.user_metadata?.active_date_range) {
      setActiveDate([new Date(`${user?.user_metadata?.active_date_range[0]}T00:00`), new Date(`${user?.user_metadata?.active_date_range[1]}T00:00`)]);
    }
  }, [user?.user_metadata?.active_projects, user?.user_metadata?.active_date_range]);

  useEffect(() => {
    if (user && !activeDateDirty && activeDate) {
      if (user?.user_metadata?.active_date_range == null) {
        updateDateRange(activeDate);
      }

      setActiveDateDirty(false);
    }
  }, [activeDate, activeDateDirty, user?.user_metadata]);

  const subLinks = links.map((link) => (
    <UnstyledButton
      key={link.label}
      disabled={link.disabled}
      style={{ paddingLeft: link.indent ? 30 : 0 }}
      onClick={() => {
        addTab(link);
      }}
      className={cx(classes.mainLink, {
        [classes.mainLinkActive]: link.id === activeTab,
      })}
    >
      <div className={classes.mainLinkInner}>
        <link.icon size={20} className={classes.mainLinkIcon} stroke={1.5} />
        <span>{link.label}</span>
      </div>
      {data && data.some((val) => val.name === link.id) && (
        <Badge size="sm" variant="filled" className={classes.mainLinkBadge}>
          {data.find((val) => val.name === link.id)["count"]}
        </Badge>
      )}
    </UnstyledButton>
  ));

  return (
    <Navbar width={{ sm: 280 }} p="md" className={classes.navbar}>
      <Center>
        <img
          src={"./logo/" + user?.user_metadata?.tenant_id + ".png"}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = "./c192.png";
          }}
          style={{ width: 60, borderRadius: "12px" }} />
      </Center>

      <Navbar.Section className={classes.section}>
        <Menu position="right-start" withArrow withinPortal trigger="hover">
          <Menu.Target>
            <UserButton
              name={user?.user_metadata?.name}
              email={user?.email}
              icon={<IconSelector size="0.9rem" stroke={1.5} />}
            />
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item icon={<IconUserQuestion size="0.9rem" stroke={1.5} />} onClick={onChangePasswordClick}>
              Alterar senha
            </Menu.Item>
            <Menu.Item
              icon={<IconLogout size="0.9rem" stroke={1.5} />}
              onClick={onLogoutClick}
            >
              Sair
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Navbar.Section>

      <Navbar.Section className={classes.section}>
        <div className={classes.collectionsHeader}>
          <MultiSelect
            disableSelectedItemFiltering
            onDropdownOpen={mutate}
            value={activeProjects}
            itemComponent={Item}
            valueComponent={Value}
            rightSection={
              activeProjects == null || projects == null ? (
                <Loader size="sm" />
              ) : (
                <></>
              )
            }
            data={
              (projects &&
                projects.map((p) => ({ value: p.id, label: p.name }))) ||
              []
            }
            onChange={(e) => {
              setActiveProjects(e);
            }}

            onDropdownClose={async (e) => {
              const { error } = await supabase
                .from("users")
                .update({ active_projects: activeProjects })
                .eq("id", user.id);

              await supabase.auth.refreshSession();

              try {
                mutateGlobal(
                  () => true,
                  undefined,
                  { revalidate: true }
                )
              }
              catch (ex) {
                console.log(ex);
              }
            }}
            placeholder="Clique para selecionar"
            radius="md"
            label="Filtro Projetos"
            variant="filled"
            size="xs"
            mb="sm"
          />
        </div>

        <div className={classes.collectionsHeader}>
          <MonthPickerInput label="Filtro Data" variant="filled" value={activeDate} onChange={setActiveDate} type="range" />
        </div>
      </Navbar.Section>

      <Navbar.Section className={classes.section}>
        <Flex mb="md" gap="md"
          justify="center"
          align="center"
          direction="row"
          wrap="nowrap">

          <ColorToggle />
        </Flex>
      </Navbar.Section>

      <Navbar.Section className={classes.section} grow component={ScrollArea} >
        <div className={classes.collections}>{subLinks}</div>
      </Navbar.Section>
    </Navbar>
  );
}
