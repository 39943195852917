import Uppy from "@uppy/core";
import { useEffect, useContext } from "react";
import { Dashboard } from "@uppy/react";

import DashboardModal from "@uppy/react/lib/DashboardModal";
import Webcam from "@uppy/webcam";
import Tus from "@uppy/tus";
import Compressor from "@uppy/compressor";
import GoldenRetriever from "@uppy/golden-retriever";
import ImageEditor from "@uppy/image-editor";

import React, { createContext, useState, useRef } from "react";

import Portuguese from "@uppy/locales/lib/pt_BR";

import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import "@uppy/image-editor/dist/style.css";

const token =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyAgCiAgICAicm9sZSI6ICJhbm9uIiwKICAgICJpc3MiOiAic3VwYWJhc2UtZGVtbyIsCiAgICAiaWF0IjogMTY0MTc2OTIwMCwKICAgICJleHAiOiAxNzk5NTM1NjAwCn0.dc_X5iR_VP_qT0zsiyj_I_OZ2T9FtRU2BBNWN8Bu4GE";
const supabaseUploadURL = `https://cccfacilcontrole.com.br/storage/v1/upload/resumable/`;

const UploaderContext = createContext(undefined);

export function UploaderProvider({ children }) {
  const [bucketName, setBucketName] = useState("teste3");
  const [folderName, setFodlerName] = useState("foldername");

  const promise = useRef(undefined);

  const [isOpen, setIsOpen] = useState(false);

  const [uppy] = useState(() => {
    const result = new Uppy({ autoProceed: true, locale: Portuguese })
      .use(Webcam)
      .use(Compressor)
      .use(ImageEditor, {
        id: "ImageEditor",
        quality: 0.8,
        cropperOptions: {
          viewMode: 1,
          background: false,
          autoCropArea: 1,
          responsive: true,
        },
      })
      .use(Tus, {
        endpoint: supabaseUploadURL,
        headers: {
          authorization: `Bearer ${token}`,
        },
        chunkSize: 4 * 1024 * 1024,
        allowedMetaFields: [
          "bucketName",
          "objectName",
          "contentType",
          "cacheControl",
        ],
      })
      .use(GoldenRetriever, { serviceWorker: true });

    result.on("file-added", (file) => {
      file.meta = {
        ...file.meta,
        bucketName: bucketName,
        objectName: folderName ? `${folderName}/${file.name}` : file.name,
        contentType: file.type,
      };
    });

    result.on("complete", (result) => {
      promise.current.resolve(result);
    });

    return result;
  });

  const show = ({ folderName, bucketName, maxNumberOfFiles = 30 }) => {
    setIsOpen(true);
    setFodlerName(folderName);
    setBucketName(bucketName);

    uppy.setOptions({
      restrictions: { maxNumberOfFiles },
      autoProceed: true,
    });

    return new Promise((resolve, reject) => {
      promise.current = { resolve, reject };
    });
  }

  return (
    <UploaderContext.Provider value={{ show }}>
      <DashboardModal
        uppy={uppy}
        open={isOpen}
        onRequestClose={() => setIsOpen(false)}
        disablePageScrollWhenModalOpen
        showProgressDetails
        plugins={["Webcam", "Instagram", "ImageEditor"]}
      />
      {children}
    </UploaderContext.Provider>
  );
}

export default function useUploader() {
  return useContext(UploaderContext);
}
