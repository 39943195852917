import { useState } from "react";
import { useUser, useSupabaseClient } from "@supabase/auth-helpers-react";

import { ColorToggle } from "./ColorToggle";

import {
  createStyles,
  Header,
  Autocomplete,
  Button,
  Group,
  Text,
  Menu,
  Avatar,
  UnstyledButton,
  rem,
} from "@mantine/core";

import {
  IconLogout,
  IconHeart,
  IconStar,
  IconMessage,
  IconSettings,
  IconPlayerPause,
  IconTrash,
  IconSwitchHorizontal,
  IconChevronDown,
  IconCalendarPlus,
  IconPassword,
  IconUserQuestion,
} from "@tabler/icons-react";

import { useTabs } from "./lib/Tabs";

import { useDisclosure } from "@mantine/hooks";


const useStyles = createStyles((theme) => ({
  header: {
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
    backgroundColor: theme.fn.variant({
      variant: "filled",
      color: theme.primaryColor,
    }).background,
  },

  inner: {
    height: rem(10),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    maxWidth: "1300px",
    marginLeft: "auto",
    marginRight: "auto",
  },

  links: {
    [theme.fn.smallerThan("md")]: {
      display: "none",
    },
  },

  user: {
    color: theme.white,
    padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
    borderRadius: theme.radius.sm,
    transition: "background-color 100ms ease",

    "&:hover": {
      backgroundColor: theme.fn.lighten(
        theme.fn.variant({ variant: "filled", color: theme.primaryColor })
          .background,
        0.1
      ),
    },

    [theme.fn.smallerThan("xs")]: {
      display: "none",
    },
  },

  userActive: {
    backgroundColor: theme.fn.lighten(
      theme.fn.variant({ variant: "filled", color: theme.primaryColor })
        .background,
      0.1
    ),
  },

  link: {
    display: "block",
    lineHeight: 1,
    padding: `${rem(8)} ${rem(12)}`,
    borderRadius: theme.radius.sm,
    textDecoration: "none",
    color: theme.white,
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    "&:hover": {
      backgroundColor: theme.fn.lighten(
        theme.fn.variant({ variant: "filled", color: theme.primaryColor })
          .background,
        0.1
      ),
    },
  },
}));

export function HeaderSection() {
  const user = useUser();

  const supabase = useSupabaseClient();

  const { addTab } = useTabs();

  const [opened, { toggle }] = useDisclosure(false);
  const { classes, theme, cx } = useStyles();
  const [userMenuOpened, setUserMenuOpened] = useState(false);


  return (
    <Header height={10} className={classes.header} mb={0}>
      <div className={classes.inner}>
      </div>
    </Header>
  );
}
/*
export function HeaderSection() {
  const user = useUser();

  const tabs = ["a", "b", "c"];

  const { classes, theme, cx } = useStyles();
  const [opened, { toggle }] = useDisclosure(false);
  const [userMenuOpened, setUserMenuOpened] = useState(false);

  const items = tabs.map((tab) => (
    <Tabs.Tab value={tab} key={tab}>
      {tab}
    </Tabs.Tab>
  ));

  return (
    <Header height={60} p="xs" className={classes.header}>
      <Group position="apart">
        <Burger
          opened={opened}
          onClick={toggle}
          className={classes.burger}
          size="sm"
          color={theme.white}
        />

        <Menu
          width={260}
          position="bottom-end"
          transitionProps={{ transition: "pop-top-right" }}
          onClose={() => setUserMenuOpened(false)}
          onOpen={() => setUserMenuOpened(true)}
          withinPortal
        >
          <Menu.Target>
            <UnstyledButton
              className={cx(classes.user, {
                [classes.userActive]: userMenuOpened,
              })}
            >
              <Group spacing={7}>
                <Avatar
                  src={
                    "https://images.unsplash.com/photo-1508214751196-bcfd4ca60f91?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=255&q=80"
                  }
                  alt={user?.email}
                  radius="xl"
                  size={20}
                />
                <Text
                  weight={500}
                  size="sm"
                  sx={{ lineHeight: 1, color: theme.white }}
                  mr={3}
                >
                  {user?.email}
                </Text>
                <IconChevronDown size={rem(12)} stroke={1.5} />
              </Group>
            </UnstyledButton>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item
              icon={
                <IconHeart
                  size="0.9rem"
                  stroke={1.5}
                  color={theme.colors.red[6]}
                />
              }
            >
              Liked posts
            </Menu.Item>
            <Menu.Item
              icon={
                <IconStar
                  size="0.9rem"
                  stroke={1.5}
                  color={theme.colors.yellow[6]}
                />
              }
            >
              Saved posts
            </Menu.Item>
            <Menu.Item
              icon={
                <IconMessage
                  size="0.9rem"
                  stroke={1.5}
                  color={theme.colors.blue[6]}
                />
              }
            >
              Your comments
            </Menu.Item>

            <Menu.Label>Settings</Menu.Label>
            <Menu.Item icon={<IconSettings size="0.9rem" stroke={1.5} />}>
              Account settings
            </Menu.Item>
            <Menu.Item
              icon={<IconSwitchHorizontal size="0.9rem" stroke={1.5} />}
            >
              Change account
            </Menu.Item>
            <Menu.Item icon={<IconLogout size="0.9rem" stroke={1.5} />}>
              Logout
            </Menu.Item>

            <Menu.Divider />

            <Menu.Label>Danger zone</Menu.Label>
            <Menu.Item icon={<IconPlayerPause size="0.9rem" stroke={1.5} />}>
              Pause subscription
            </Menu.Item>
            <Menu.Item
              color="red"
              icon={<IconTrash size="0.9rem" stroke={1.5} />}
            >
              Delete account
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Group>
    </Header>
  );
}
*/
