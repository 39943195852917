import { useEffect, useState  } from "react";

import { IconX, IconCheck } from '@tabler/icons-react';
import { Progress, Popover, Box } from '@mantine/core';

import {
    Text,
    Avatar,
    Paper,
    Flex,
    rem,
    Space,
    Tabs,
    Input,
    Button,
    Badge,
    Grid,
    ActionIcon,
    HoverCard,
    Group,
    LoadingOverlay,
    ScrollArea,
    PasswordInput,
    Select,
    useMantineTheme,
    Tooltip,
} from "@mantine/core";

import { modals } from "@mantine/modals";

import { useFocusTrap } from '@mantine/hooks';

import { useForm } from '@mantine/form';

import { useSupabaseClient } from "@supabase/auth-helpers-react";

function PasswordRequirement({ meets, label }) {
    return (
        <Text
            color={meets ? 'teal' : 'red'}
            sx={{ display: 'flex', alignItems: 'center' }}
            mt={7}
            size="sm"
        >
            {meets ? <IconCheck size="0.9rem" /> : <IconX size="0.9rem" />} <Box ml={10}>{label}</Box>
        </Text>
    );
}

const requirements = [
    { re: value => /[0-9]/.test(value), label: 'Includes number' },
    { re: value => /[a-z]/.test(value), label: 'Includes lowercase letter' },
    { re: value => /[A-Z]/.test(value), label: 'Includes uppercase letter' },
    { re: value => /[$&+,:;=?@#|'<>.^*()%!-]/.test(value), label: 'Includes special symbol' },
];


function getStrength(password) {
    let multiplier = password.length > 5 ? 0 : 1;

    requirements.forEach((requirement) => {
        if (!requirement.re(password)) {
            multiplier += 1;
        }
    });

    return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 10);
}


function ValidatedPasswordInput(props) {
    const [popoverOpened, setPopoverOpened] = useState(false);

    const checks = props.requirements.map((requirement, index) => (
        <PasswordRequirement key={index} label={requirement.label} meets={requirement.re(props.value)} />
    ));

    const strength = getStrength(props.value);
    const color = strength === 100 ? 'teal' : strength > 50 ? 'yellow' : 'red';

    return (

        <Popover opened={popoverOpened} withinPortal position="bottom" width="target" transitionProps={{ transition: 'pop' }}>
            <Popover.Target>
                <div
                    onFocusCapture={() => setPopoverOpened(true)}
                    onBlurCapture={() => setPopoverOpened(false)}
                >
                    <PasswordInput
                        withAsterisk
                        {...props}
                    />
                </div>
            </Popover.Target>
            <Popover.Dropdown>
                <Progress color={color} value={strength} size={5} mb="xs" />
                <PasswordRequirement label="Includes at least 6 characters" meets={props.value.length > 5} />
                {checks}
            </Popover.Dropdown>
        </Popover>

    );
}

function ChangePassword({ canClose }) {
    const focusTrapRef = useFocusTrap();

    const supabase = useSupabaseClient();

    const form = useForm({
        initialValues: {
            password: '',
            repeatPassword: '',
        },

        validate: {
            password: (value) => requirements.every(req => req.re(value)) ? null : 'Senha inválida',
            repeatPassword: (value) => repeatRequirements.every(req => req.re(value)) ? null : 'Senha inválida',
        },
    });

    const repeatRequirements = [
        ...requirements,
        { re: value => value === form.values.password, label: 'Matches password' },
    ];

    const handleSubmit = async (values) => {
        const { data, error } = await supabase.auth.updateUser({
            password: values.password
        });

        if (error) {
            return;
        }

        await supabase.auth.refreshSession();

        modals.closeAll();
    };

    return <form onSubmit={form.onSubmit((values) => handleSubmit(values))} ref={focusTrapRef}>


        <ValidatedPasswordInput
            label="Senha"
            placeholder="Nova senha"
            mt="md"
            size="md"
            {...form.getInputProps('password')}
            requirements={requirements}
        />
        <ValidatedPasswordInput
            label="Repetir senha"
            placeholder="Nova senha"
            mt="md"
            size="md"
            {...form.getInputProps('repeatPassword')}
            requirements={repeatRequirements}
        />

        <Group position="right" mt="md">
            <Button type="submit">Salvar</Button>
        </Group>
    </form>
}

export function openChangePassword(canClose = false) {
    modals.open({
        size: "md",
        position: "center",
        modalId: "change-password",
        title: "Alterar senha",
        closeOnClickOutside: canClose,
        closeOnEscape: canClose,
        withCloseButton: canClose,
        children: <ChangePassword canClose={canClose} />
    });
}
