import { createContext, useContext, useState, useEffect } from "react";

const TabsContext = createContext(null);

function TabsProvider({ children }) {
  const [tabs, setTabs] = useState(() => {
    const tabsJson = localStorage.getItem("TABS");

    if (tabsJson) {
      return JSON.parse(tabsJson);
    } else {
      return [
        {
          label: "Painel de Controle",
          component: "Home",
          id: "Home",
        },
      ];
    }
  });

  const addTab = ({ id, component, label, props }) => {
    if (id && component && label) {
      for (let tab of tabs) {
        if (tab.id == id) {
          setActiveTab(tab.id);
          return;
        }
      }

      setTabs([
        ...tabs,
        {
          id,
          component,
          label,
          props
        },
      ]);

      setActiveTab(id);
    }
  };

  const removeTab = (id) => {
    const newTabs = tabs.filter((tab) => tab.id != id);

    setTabs(newTabs);

    if (activeTab == id) {
      setActiveTab("Home");
    }
  };

  const [activeTab, setActiveTab] = useState(() => {
    return localStorage.getItem("ACTIVE_TAB") || "Home";
  });

  useEffect(() => {
    localStorage.setItem("ACTIVE_TAB", activeTab);
  }, [activeTab]);

  useEffect(() => {
    localStorage.setItem("TABS", JSON.stringify(tabs));
  }, [tabs]);

  return (
    <TabsContext.Provider
      value={{
        tabs,
        activeTab,
        setTabs,
        addTab,
        removeTab,
        setActiveTab,
      }}
    >
      {children}
    </TabsContext.Provider>
  );
}

function useTabs() {
  return useContext(TabsContext);
}

export { TabsProvider, useTabs };
