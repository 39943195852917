import React, { Suspense, useEffect, memo } from "react";
import { NavbarSection } from "./Navbar.jsx";
//import { HeaderSection } from "./Header";

import dayjs from 'dayjs';
import {
  AppShell,
  Tabs,
  CloseButton,
  createStyles,
  rem,
  ActionIcon,
  LoadingOverlay, 
  Box, 
  Stack,
  Tooltip
} from "@mantine/core";

import { TabsProvider, useTabs } from "./lib/Tabs";

import { UploaderProvider } from "./Uploader";

import { useUser, useSupabaseClient } from "@supabase/auth-helpers-react";

import { openChangePassword } from "./ChangePassword";

import {
  IconPoint, IconRefresh,
} from "@tabler/icons-react";
import { HeaderSection } from "./Header";

const TablePage = React.lazy(() => import("./TablePage"));
const HomePage = React.lazy(() => import("./pages/Home"));

const UsersPage = (props) => {
  return (
    <TablePage
      {...props}
      tableName={"v_users"}
      schema={[
        {
          header: "Email",
          accessorKey: "email",
          size: 150,
          enableEditing: false,
          enableCreating: true,
        },
        {
          header: "Nome",
          size: 100,
          accessorKey: "name",
          enableEditing: true,
        },
        {
          header: "Grupos",
          accessorKey: "groups",
          type: "autocomplete",
          tableName: "v_groups",
          filters: [["is_user_group", "eq", false]],
          fieldName: "name",
          groupName: "type",
          enableEditing: true,
        },
        {
          header: "Projetos",
          accessorKey: "projects",
          type: "autocomplete",
          tableName: "project",
          fieldName: "name",
          groupName: "type",
        },
        {
          header: "Admin",
          size: 60,
          enableEditing: false,
          accessorKey: "is_admin",
          type: "boolean",
        },
        {
          header: "Último login",
          size: 80,
          maxSize: 80,
          enableEditing: false,
          accessorKey: "last_sign_in_at",
          type: "datetime",
        },
      ]}
    />
  );
};

const GroupsPage = (props) => {
  return (
    <TablePage
      {...props}
      tableName={"v_groups"}
      filters={[["is_user_group", "eq", false]]}
      schema={[
        {
          header: "Nome",
          accessorKey: "name",
          size: 120,
          enableEditing: true,
        },
        {
          header: "Usuários",
          accessorKey: "users",
          size: 400,
          enableEditing: true,
          type: "autocomplete",
          tableName: "v_users",
          fieldName: "email",
        },
      ]}
    />
  );
};

const ProjectsPage = (props) => {
  return (
    <TablePage
      {...props}
      tableName={"v_project"}
      schema={[
        {
          header: "Nome",
          accessorKey: "name",
          size:150,
          enableEditing: true,
        },
        {
          header: "Recuperação Judicial",
          size:100,
          accessorKey: "is_rj",
          enableEditing: true,
          type: "boolean",
        },
        {
          header: "Início",
          accessorKey: "start_date",
          enableEditing: true,
          type: "date",
        },
        {
          header: "Término",
          accessorKey: "end_date",
          enableEditing: true,
          type: "date",
        },
        {
          header: "Grupos/Usuários Associados",
          accessorKey: "groups",
          size:300,
          enableEditing: true,
          type: "autocomplete",
          tableName: "v_groups",
          fieldName: "name",
        },
      ]}
    />
  );
};

const components = {
  Home: HomePage,
  Users: UsersPage,
  Groups: GroupsPage,
  Projects: ProjectsPage
};

const useStyles = createStyles((theme) => ({
  tabs: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },

  tabsList: {
    borderBottom: "0 !important",
    paddingLeft: 20,
    paddingRight: 20,
  },

  tab: {
    fontWeight: 500,
    height: rem(32),
    backgroundColor: "transparent",

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[5]
          : theme.colors.gray[1],
    },

    "&[data-active]": {
      backgroundColor:
        theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
      borderColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[7]
          : theme.colors.gray[4],
    },
  },

  content: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,

    border: `${rem(1)} solid ${theme.colorScheme === "dark" ? "transparent" : theme.colors.gray[4]
      }`,
  },
}));

function InnerLayout() {
  const { tabs, activeTab, setActiveTab, removeTab, setTabs } = useTabs();
  const { classes } = useStyles();

  return (
    <AppShell
      padding={"xs"}
      navbar={<NavbarSection />}
      header={<HeaderSection />}
      styles={(theme) => ({
        main: {
          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.dark[6]
              : theme.colors.gray[2],
        },
      })}
    >
      <Tabs
        value={activeTab}
        onTabChange={setActiveTab}
        variant="outline"
        classNames={{
          root: classes.tabs,
          tabsList: classes.tabsList,
          tab: classes.tab,
        }}
      >
        <Tabs.List>
          {tabs.map((tab) => (
            <Tabs.Tab
              icon={<Tooltip withinPortal label="Atualizar dados"><ActionIcon variant="subtle" color="primary" loading={tab.refreshRequested} onClick={() => {
                const clone = [...tabs];

                const index = clone.indexOf(tab);

                if (index >= 0) {
                  clone[index] = { ...clone[index], refreshRequested: true }

                  setTabs(clone);
                }
              }}><IconRefresh size="0.8rem" /></ActionIcon></Tooltip>}
              rightSection={
                tab.id !== "Home" && (
                  <CloseButton
                    disabled={tab.id === "Home"}
                    color="dark"
                    variant="subtle"
                    radius="xl"
                    onClick={(e) => {
                      removeTab(tab.id);

                      e.stopPropagation();
                    }}
                  />
                )
              }
              value={tab.id}
            >
              {tab.label}
            </Tabs.Tab>
          ))}
        </Tabs.List>
      </Tabs>

      <div className={classes.content}>
        {tabs.map((tab) => {
          const { id, component, props, refreshRequested, onRefresh } = tab;

          const Component = components[component];

          return (
            <Suspense
              key={id}
              fallback={
                <div className={id === activeTab ? "active" : "hidden"}>
                  <LoadingOverlay visible></LoadingOverlay>
                </div>
              }
            >
              <Component
                className={id === activeTab ? "active" : "hidden"}
                tabId={id}
                refreshRequested={refreshRequested}
                onRefresh={() => {
                  const clone = [...tabs];

                  const index = clone.indexOf(tab);

                  if (index >= 0) {
                    clone[index] = { ...clone[index], refreshRequested: false }

                    setTabs(clone);
                  }
                }}
                {...props}
              />
            </Suspense>
          );
        })}
      </div>
    </AppShell>
  );
}

function LayoutComponent() {
  const user = useUser();
  const supabase = useSupabaseClient();

  useEffect(() => {
    if (user?.user_metadata?.force_change_password) {
      openChangePassword(false);
    }
  }, [user]);

  return (
    <TabsProvider>
      <UploaderProvider>
        <InnerLayout />
      </UploaderProvider>
    </TabsProvider>
  );
}

const Layout = memo(LayoutComponent);

export { Layout };
