import {
  Paper,
  createStyles,
  TextInput,
  PasswordInput,
  Checkbox,
  Button,
  Title,
  Text,
  Anchor,
  rem,
} from "@mantine/core";

import { Auth } from '@supabase/auth-ui-react'

import React, { useState } from "react";

import { IconX } from "@tabler/icons-react";

import { notifications } from "@mantine/notifications";

import { useUser, useSupabaseClient } from "@supabase/auth-helpers-react";

import { useNavigate } from "react-router-dom";

import supabaseClient from "./supabase";

import {
  // Import predefined theme
  ThemeSupa,
} from '@supabase/auth-ui-shared'

const useStyles = createStyles((theme) => ({
  anchor: {
    width: "100%",
  },
  wrapper: {
    minHeight: rem(900),
    backgroundSize: "cover",
    backgroundImage:
      "url(https://images.unsplash.com/photo-1484242857719-4b9144542727?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1280&q=80)",
  },

  form: {
    borderRight: `${rem(1)} solid ${theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[3]
      }`,
    minHeight: rem(900),
    maxWidth: rem(450),
    paddingTop: rem(80),

    [theme.fn.smallerThan("sm")]: {
      maxWidth: "100%",
    },
  },

  title: {
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },
}));

export default function AuthUI() {
  const { classes } = useStyles();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const [mode, setMode] = useState("Login");

  const navigate = useNavigate();

  const supabase = useSupabaseClient();

  const handleLogin = async () => {
    try {
      setLoading(true);

      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (error) {
        notifications.show({
          withCloseButton: true,
          autoClose: 5000,
          title: "Login Error",
          message: error.message,
          color: "red",
          icon: <IconX />,
          loading: false,
        });
      } else {
        navigate("/");
      }
    } catch (ex) {
      debugger;
    } finally {
      setLoading(false);
    }
  };

  const handleMagicLink = async () => {
    try {
      setLoading(true);

      const { data, error } = await supabase.auth.signInWithOtp({
        email,
        options: {
          emailRedirectTo: 'https://cadastro.drwilliamultrassom.com.br',
        },
      });

      if (error) {
        notifications.show({
          withCloseButton: true,
          autoClose: 5000,
          message: error.message,
          color: "red",
          icon: <IconX />,
          loading: false,
        });
      }
      else {
        notifications.show({
          withCloseButton: true,
          autoClose: 5000,
          title: "E-mail enviado com sucesso!",
          message: "Caso o e-mail não esteja na sua caixa de entrada, favor verificar a caixa de spam.",
          color: "green",
          icon: <IconX />,
          loading: false,
        });
      }
    }
    finally {
      setLoading(false);
    }
  }

  return (
    <div className={classes.wrapper}>
      <Paper className={classes.form} radius={0} p={30}>
        <Title order={2} className={classes.title} ta="center" mt="md" mb={50}>
          Bem-vindo ao WJS Cadastros!
        </Title>

        {mode == "Login" && (
          <>
            <TextInput
              label="Email"
              placeholder="hello@gmail.com"
              size="md"
              value={email}
              onChange={(event) => setEmail(event.currentTarget.value)}
            />
            <PasswordInput
              label="Senha"
              placeholder="Your password"
              mt="md"
              size="md"
              value={password}
              onChange={(event) => setPassword(event.currentTarget.value)}
            />
            <Button
              loading={loading}
              fullWidth
              mt="xl"
              size="md"
              onClick={handleLogin}
            >
              Login
            </Button>

            <Anchor component="button" type="button" align="center" onClick={() => {
              setMode("MagicLink");
              setEmail("");
            }} mt="md" className={classes.anchor}>
              Esqueceu a senha? Clique para fazer login sem senha!
            </Anchor>
          </>
        )}

        {mode == "MagicLink" && (
          <>
            <TextInput
              label="Email"
              placeholder="hello@gmail.com"
              size="md"
              value={email}
              onChange={(event) => setEmail(event.currentTarget.value)}
            />
            <Button
              loading={loading}
              fullWidth
              mt="xl"
              size="md"
              onClick={handleMagicLink}
            >
              Enviar link de acesso por email
            </Button>

            <Anchor component="button" type="button" align="center" onClick={() => {
              setMode("Login");
              setEmail("");
            }} mt="md" className={classes.anchor}>
              Voltar para login com senha
            </Anchor>
          </>
        )}
      </Paper>
    </div>
  );
}
